/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import AES from 'crypto-js/aes';
import { RespondentAnswerTypes, ExternalQuestion } from '@sm/question-widgets/respondent-survey';
import Cookies from 'js-cookie';
import { AppDispatch, RootState } from '~app/storeV2';
import { decryptAnswers } from '~app/pages/SurveyTaking/helper/localStoreAnswers';

import {
  QuestionAnswers,
  SubmitMutation,
  PageQuestions,
  CurrentSessionSurvey,
  SurveyTakingCurrentPage,
  StaticContextEnvironment,
  SubmitMutationForMultipage,
} from '../types';

import { SurveyFormat } from '~app/components/Survey/SurveyFormat/constants';
import { validate, deferToAfterFocusChanged } from '../../validation';
import { transformToSurveyErrors } from '../../errors/transformToSurveyErrors';
import { removeErrorsById, setErrorsById } from './errorsSlice';
import { mapSurveyAnswersToResponse } from '../../helper/mapSurveyAnswersToResponse';
import { DEFAULT_SUBMIT_RESULT } from '../../constants';
import { GetSpageSessionQuery, RespApiAnswerInput } from '~lib/generatedGqlTypes';

// ========== INITIAL STATE
const emptyPagePath: readonly number[] = Object.freeze([]);
export type SurveyTakingPayload = {
  respondentSession: GetSpageSessionQuery['spageSession'];
  surveyTakingCurrentPage: SurveyTakingCurrentPage;
};

export type SurveyMode = (typeof SurveyView)[keyof typeof SurveyView];

export type SurveyState = {
  surveyTakingPayload?: SurveyTakingPayload;
  /** The survey object */
  survey?: CurrentSessionSurvey;
  /** The active page object */
  activePage?: SurveyTakingCurrentPage;
  /** The collector object */
  collector?: GetSpageSessionQuery['spageSession']['collector'];
  /** The client token */
  clientToken?: string;
  /** The answers for the current page */
  answers?: QuestionAnswers;
  questionsWithAnswers?: QuestionAnswers;
  questions?: PageQuestions;
  /** To track what view is shown to user */
  surveyView: SurveyMode;
  /* Redirect URL based off collector's Survey End Page feature */
  surveyEndPageUrl: string | null;
  /* Encrypted SMParams when instant results is enabled */
  encryptedInstantResultsSmParam: string | null;
  currentPageId?: string;
  encrytedSurveyParams?: string;
  pagePath?: number[];
  // TODO: Collector Key null is it a valid case?
  collectorKey: string | null;
  /* StaticContext Environment */
  environment?: StaticContextEnvironment;
  surveyOwnerPackageId?: string;
};

/** Different views that are designed like a survey page but have different content */
export const SurveyView = {
  Taking: 'TAKING',
  ThankYou: 'THANK_YOU',
  Password: 'PASSWORD',
} as const;

export const initialState: SurveyState = {
  surveyTakingPayload: undefined,
  survey: undefined,
  activePage: undefined,
  collector: undefined,
  clientToken: undefined,
  questions: { items: [] },
  answers: {},
  questionsWithAnswers: undefined,
  surveyView: SurveyView.Taking,
  surveyEndPageUrl: 'abc',
  encryptedInstantResultsSmParam: 'abc',
  currentPageId: undefined,
  pagePath: undefined,
  collectorKey: null,
  encrytedSurveyParams: undefined,
  environment: undefined,
};

type SurveyCompleteAction = {
  smParamForSurveyComplete: string;
  reqLocale: string;
};

type SetCookieArgs = {
  name: string;
  value: string;
  expiration?: number | Date;
};

type SurveyPagePayload = {
  activePage?: SurveyTakingCurrentPage;
  questions?: PageQuestions;
  answers?: QuestionAnswers;
  clientToken: string;
};

type PostSurveyCompleteCleanupArgs = {
  collectorKey: string | null;
};

// ========== SLICE

const sliceName = 'survey';

export const surveySlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    initEnvironment: (state, action: PayloadAction<StaticContextEnvironment>) => {
      state.environment = action.payload;
    },
    initializeSurvey: (state, action: PayloadAction<SurveyTakingPayload>) => {
      state.surveyTakingPayload = action.payload;
      state.survey = action.payload.respondentSession?.survey;
      state.collector = action.payload.respondentSession?.collector;
      state.surveyEndPageUrl = action.payload.respondentSession?.respondent.surveyEndPageUrl;
      state.encryptedInstantResultsSmParam =
        action.payload.respondentSession?.respondent?.encryptedInstantResultsSmParam;
      state.activePage = action.payload.surveyTakingCurrentPage ?? {};
      state.pagePath = action.payload.respondentSession.pagePath;
      state.currentPageId = action.payload.surveyTakingCurrentPage?.surveyPage?.id;
      state.questions = action.payload.surveyTakingCurrentPage?.surveyPage?.surveyPageQuestions;
      state.encrytedSurveyParams = action.payload.respondentSession?.encryptedSmParam ?? undefined;
      state.clientToken = action.payload.respondentSession?.respondent?.clientToken ?? undefined;

      // initialize answers
      state.answers =
        action.payload.surveyTakingCurrentPage?.surveyPage?.surveyPageQuestions?.items?.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: {
              questionId: curr.id,
              values: [],
              touched: false,
              isDirty: false,
            },
          }),
          {}
        ) ?? {};
    },
    setAnswers: (state, action: PayloadAction<QuestionAnswers | undefined | {}>) => {
      state.answers = action.payload;
    },
    setQuestions: (state, action: PayloadAction<PageQuestions>) => {
      state.questions = action.payload;
    },
    setActivePage: (state, action: PayloadAction<SurveyPagePayload>) => {
      const { activePage, questions, answers } = action.payload;
      state.activePage = activePage;
      state.questions = questions;
      state.answers = answers;
      state.currentPageId = activePage?.surveyPage?.id;
      state.clientToken = action.payload.clientToken;
    },
    updateAnswers: (state, action: PayloadAction<QuestionAnswers>) => {
      const mergedAnswers = {
        ...state.answers,
        ...action.payload,
      };
      state.answers = mergedAnswers;
    },
    updateQuestionsWithNewAnswers: (state, action: PayloadAction<QuestionAnswers>) => {
      state.questionsWithAnswers = action.payload;
    },
    setClientToken: (state, action: PayloadAction<string>) => {
      state.clientToken = action.payload;
    },
    setCurrentPageId: (state, action: PayloadAction<string>) => {
      state.currentPageId = action.payload;
    },
    setEncrytedSurveyParams: (state, action: PayloadAction<string>) => {
      state.encrytedSurveyParams = action.payload;
    },
    setPagePath: (state, action: PayloadAction<number[]>) => {
      state.pagePath = action.payload;
    },
    setCollectorKey: (state, action: PayloadAction<string>) => {
      state.collectorKey = action.payload;
    },
    setSurveyView: (state, action: PayloadAction<SurveyMode>) => {
      state.surveyView = action.payload;
    },
    setSurveyOwnerPackageId: (state, action: PayloadAction<string>) => {
      state.surveyOwnerPackageId = action.payload;
    },
    setCookie: (state, action: PayloadAction<SetCookieArgs>) => {
      const { environment: { domain, tld } = {} } = state;
      const { name, value, expiration } = action.payload;

      Cookies.set(name, value, {
        expires: expiration,
        domain: `.${domain}.${tld}`,
        secure: true,
        sameSite: 'None',
      });
    },
    surveyComplete: (state, action: PayloadAction<SurveyCompleteAction>) => {
      const isThankYouEnabled = state.collector?.thankYouPage?.isEnabled ?? false;
      const surveyResultsUrl = state.collector?.surveyResultsUrl ?? null;

      const { smParamForSurveyComplete, reqLocale } = action.payload;

      const { environment } = state;
      const isCurrentViewCustomThankYou = state.surveyView === 'THANK_YOU';

      const showQuizResultsPage = state.survey?.isQuiz && state.survey.quizOptions?.showResults;
      const showInstantResultsPage = !showQuizResultsPage && surveyResultsUrl;
      const showThankYouView = !showInstantResultsPage && isThankYouEnabled && !isCurrentViewCustomThankYou;
      const surveyEndPageRedirect = !showThankYouView;

      let redirectUrl: string | null = null;

      if (showQuizResultsPage) {
        if (surveyResultsUrl) {
          Cookies.set('sm_ir', state.encryptedInstantResultsSmParam ?? '', {
            domain: `.${environment?.domain}.${environment?.tld}`,
            secure: true,
            sameSite: 'None',
          });
        }

        redirectUrl = `/r/quiz/results?sm=${smParamForSurveyComplete}&lang=${reqLocale}`;
      } else if (showInstantResultsPage) {
        Cookies.set('sm_ir', state.encryptedInstantResultsSmParam ?? '', {
          domain: `.${environment?.domain}.${environment?.tld}`,
          secure: true,
          sameSite: 'None',
        });

        redirectUrl = `${surveyResultsUrl}?sm=${smParamForSurveyComplete}&lang=${reqLocale}`;
      } else if (showThankYouView) {
        state.surveyView = 'THANK_YOU';
      } else if (surveyEndPageRedirect) {
        redirectUrl = `${state.surveyEndPageUrl}?sm=${smParamForSurveyComplete}&lang=${reqLocale}` ?? null;
      }

      if (redirectUrl) {
        window.location.replace(redirectUrl);
      }
    },
    reset: () => initialState,
    postSurveyCompleteCleanup: (state, action: PayloadAction<PostSurveyCompleteCleanupArgs>) => {
      const { collectorKey } = action.payload;
      Cookies.remove(`RE_${collectorKey}`, { path: '/' });
      Cookies.remove(`REPID_${collectorKey}`, { path: '/' });
      window.localStorage.removeItem(`answers_${collectorKey}`);
    },
  },
});

// ========== ACTIONS

export const {
  initializeSurvey,
  initEnvironment,
  setAnswers,
  setQuestions,
  setActivePage,
  updateAnswers,
  updateQuestionsWithNewAnswers,
  setClientToken,
  reset,
  setSurveyView,
  surveyComplete,
  setCurrentPageId,
  setEncrytedSurveyParams,
  setPagePath,
  setCollectorKey,
  setCookie,
  setSurveyOwnerPackageId,
  postSurveyCompleteCleanup,
} = surveySlice.actions;

// ========== THUNKS

/**
 * Update an answer - the specific answer is inferred by the `questionId` inside the answer object.
 * Error validation is also run for the new answer and the errors state is updated accordingly.
 *
 */
export const updateAnswer = createAsyncThunk(`${sliceName}/updateAnswer`, (answer: RespondentAnswerTypes, thunkAPI) => {
  const { questionId } = answer;
  const { surveyState } = thunkAPI.getState() as RootState;
  const dispatch = thunkAPI.dispatch as AppDispatch;

  if (!answer.touched) {
    return; // no need for updates if answer was not touched
  }

  const { isValid, errors } = validate(questionId);
  deferToAfterFocusChanged(() => {
    // defer to make done with error-change click work
    if (!isValid) {
      const questionErrors = transformToSurveyErrors(errors);
      dispatch(setErrorsById({ id: questionId, newErrors: questionErrors }));
    } else {
      dispatch(removeErrorsById(questionId));
    }
  });

  const mergedAnswers = { ...surveyState.answers, ...{ [questionId]: answer } };
  const collectorKey = surveyState.collectorKey ?? 'collectKey';
  const allPageAnswers = { ...decryptAnswers(collectorKey), ...mergedAnswers };
  const encryptedAnswers = AES.encrypt(JSON.stringify(allPageAnswers), collectorKey || 'collectKey').toString();
  window.localStorage.setItem(`answers_${collectorKey || 'collectKey'}`, encryptedAnswers);

  // updating for OQAAT
  dispatch(updateQuestionsWithNewAnswers(allPageAnswers));

  dispatch(
    updateAnswers({
      [questionId]: { ...(surveyState.answers?.[questionId] ?? {}), ...answer },
    })
  );
});

/**
 * Submit the survey response.
 * Currently requires the mutation function to be passed as a parameter as it is generated by hook.
 * Note: dateStarted and dateCompleted also need to be passed in as part of a solution to
 * fix failing integration tests involving mocked timers and promises.
 *  */
// to be updated with responsesAPI integration
export const submitSurvey = createAsyncThunk(
  `${sliceName}/submit`,
  async (
    {
      mutation,
      dateStarted,
      dateCompleted,
      reqLocale,
      isValid,
      confirmationEmail,
    }: {
      mutation: SubmitMutation;
      dateStarted: string;
      dateCompleted: string;
      reqLocale: string;
      isValid: boolean;
      confirmationEmail: string | null;
    },
    { getState }
  ) => {
    const { surveyState } = getState() as RootState;
    if (surveyState.survey && surveyState.collector) {
      try {
        // Only use the answered questions in payload (SPAGE-7410)
        const responses = mapSurveyAnswersToResponse({
          questions: (surveyState.activePage?.surveyPage?.surveyPageQuestions?.items ?? []) as ExternalQuestion[],
          answers: surveyState.answers ?? {},
          pageId: surveyState.activePage?.surveyPage?.id ?? '',
          isPrevious: false,
          isValid,
        }) as Required<RespApiAnswerInput[]>; // this typecast is necessary because gql InputMaybe types expect null values, which are not allowed in the response

        const { id: collectorId, isAnonymous, thankYouPage, surveyResultsUrl } = surveyState.collector;
        const {
          version,
          language: { id: surveyLangId },
          isQuiz,
          quizOptions,
        } = surveyState.survey;
        const generateQuizResultsSMParams = isQuiz && (quizOptions?.showResults ?? false);

        /**
         * Mutation calls SurveyResponses.graphql::submitSurveyResponse, which calls
         * @see https://github.com/mntv-webplatform/smweb/blob/master/api/graphapi/src/repos/responses/index.ts#L108
         * in turn
         * @see https://code.corp.surveymonkey.com/devmonkeys/ResponseWeb/blob/develop/responseweb/views/response.py#L167
         */
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { data: { submitSurveyResponse } = {} } = await mutation({
          variables: {
            surveyId: surveyState.survey.id ?? '',
            collectionKey: surveyState.collectorKey ?? '',
            input: {
              collectorId,
              isAnonymous: isAnonymous ?? false,
              surveyVersion: version ?? 1,
              dateStarted,
              dateCompleted,
              respondentId: '',
              responses,
              generateQuizResultsSMParams,
              surveyLangId,
              redirectUrl: surveyState.surveyEndPageUrl,
              reqLocale,
              isThankYouEnabled: thankYouPage?.isEnabled ?? false,
              instantResultsUrl: surveyResultsUrl ?? null,
              multiPageAnswerInput: null,
              confirmationEmail,
            },
          },
        });

        return submitSurveyResponse;
      } catch (error: unknown) {
        // SPAGE-9381 - To be completed in server error validation story
        console.log(error);
      }
    }
    return DEFAULT_SUBMIT_RESULT;
  }
);

/*
 * Submit survey response to responsesAPI v3.
 *
 * Currently requires the mutation function to be passed as a parameter as it is generated by hook.
 * Note: dateStarted and dateCompleted also need to be passed in as part of a solution to
 * fix failing integration tests involving mocked timers and promises.
 */
export const submitResponsesV3 = createAsyncThunk(
  `${sliceName}/submitResponsesV3`,
  async (
    {
      mutation,
      dateStarted,
      dateCompleted,
      reqLocale,
      currentPageId,
      nextPageId,
      respondentId,
      isFinalSubmit,
      collectorKey,
      isValid,
      isPrevious,
    }: {
      mutation: SubmitMutationForMultipage;
      dateStarted: string;
      dateCompleted: string;
      reqLocale: string;
      currentPageId: string;
      nextPageId: string;
      respondentId: string;
      isFinalSubmit: boolean;
      collectorKey: string | null;
      isValid: boolean;
      isPrevious: boolean;
    },
    { getState }
  ) => {
    const { surveyState } = getState() as RootState;
    if (surveyState.survey && surveyState.collector) {
      try {
        // Only use the answered questions in payload (SPAGE-7410)
        const pageId = surveyState.activePage?.surveyPage?.id ?? '';
        const responses = mapSurveyAnswersToResponse({
          questions: (surveyState.activePage?.surveyPage?.surveyPageQuestions?.items ?? []) as ExternalQuestion[],
          answers: surveyState.answers ?? {},
          pageId,
          isValid,
          isPrevious,
        }) as Required<RespApiAnswerInput[]>; // this typecast is necessary because gql InputMaybe types expect null values, which are not allowed in the response

        const { id: collectorId, isAnonymous, thankYouPage, surveyResultsUrl } = surveyState.collector;
        const {
          version,
          language: { id: surveyLangId, code: languageCode },
          isQuiz,
          quizOptions,
        } = surveyState.survey;
        const generateQuizResultsSMParams = isQuiz && (quizOptions?.showResults ?? false);

        const data = await mutation({
          variables: {
            surveyId: surveyState.survey.id ?? '',
            collectionKey: collectorKey ?? '',
            languageCode,
            pageId: currentPageId === nextPageId ? currentPageId : nextPageId,
            input: {
              respondentId,
              collectorId,
              isAnonymous: isAnonymous ?? false,
              surveyVersion: version ?? 1,
              dateStarted,
              dateCompleted,
              responses,
              generateQuizResultsSMParams,
              surveyLangId,
              redirectUrl: surveyState.surveyEndPageUrl,
              reqLocale,
              isThankYouEnabled: thankYouPage?.isEnabled ?? false,
              instantResultsUrl: surveyResultsUrl ?? null,
              multiPageAnswerInput: {
                currentPageId,
                nextPageId,
                isFinalSubmit,
                collectorKey,
              },
              confirmationEmail: null,
            },
          },
        });
        return data.data?.submitSurveyResponse;
      } catch (error: unknown) {
        // To be completed in server error validation story
        console.log(error);
      }
    }

    return {
      ...DEFAULT_SUBMIT_RESULT,
      surveyPage: null,
    };
  }
);

// ========== SELECTORS

/** The survey state object */
export const selectSurveyState = (state: RootState): SurveyState => state.surveyState;

/** The survey object */
export const selectSurvey = createSelector(selectSurveyState, surveyState => surveyState.survey);

/** The current page object */
export const selectActivePage = createSelector(selectSurveyState, surveyState => surveyState.activePage);

/** The collector object */
export const selectCollector = createSelector(selectSurveyState, surveyState => surveyState.collector);

/** The client token */
export const selectClientToken = createSelector(selectSurveyState, surveyState => surveyState.clientToken);

export const selectSurveyView = createSelector(selectSurveyState, surveyState => surveyState.surveyView);

/** Survey package id */
export const selectSurveyOwnerPackageId = createSelector(
  selectSurveyState,
  surveyState => surveyState.surveyOwnerPackageId
);

/** The survey format (eg. classic or OQAAT) */
export const selectSurveyFormat = createSelector(
  selectSurvey,
  survey => survey?.format ?? SurveyFormat.ONE_QUESTION_AT_A_TIME
);

/** Object containing all answers for the current page */
export const selectAnswers = createSelector(selectSurveyState, surveyState => surveyState.answers);

export const selectQuestionsWithAnswersCount = createSelector(selectSurveyState, surveyState => {
  const completedAnswersArray = Object.values(surveyState.questionsWithAnswers ?? {});
  return completedAnswersArray.filter(a => a.values.length).length;
});

/** Object containing all answers for the current page */
export const selectQuestions = createSelector(selectSurveyState, surveyState => surveyState.questions);

/** The answer object for the question specified by Id */
export const selectAnswerById = createSelector(
  [selectAnswers, (_, questionId: string) => questionId],
  (answers, questionId) => answers?.[questionId]
);

/**
 * The total number of questions that have a value (i.e. answered), on the current page
 * note: currently only works with single-value questions
 * TODO: refactor to include answered questions on other pages once we get that data from the backend
 *  */
export const selectTotalAnswers = createSelector(selectAnswers, answers =>
  !answers ? 0 : Object.values(answers).reduce((acc: number, answer) => (answer.values?.[0]?.value ? acc + 1 : acc), 0)
);
export const selectPagePath = createSelector(selectSurveyState, surveyState => surveyState.pagePath ?? emptyPagePath);

export const selectCurrentPageId = createSelector(
  selectSurveyState,
  surveyState => surveyState.activePage?.surveyPage?.id
);

export const selectCollectorKey = createSelector(selectSurveyState, surveyState => surveyState.collectorKey);

export const selectEncrytedSurveyParams = createSelector(
  selectSurveyState,
  surveyState => surveyState.encrytedSurveyParams
);

// ========== EXPORT

export default surveySlice.reducer;
