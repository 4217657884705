import React, { useContext, useEffect, useRef } from 'react';
import logger from '@sm/logging';
import { Provider as ReduxProvider } from 'react-redux';
import { GetServerSidePropsResult } from 'next';
import Head from 'next/head';
import { defineMessages, t } from '@sm/intl';
import { SurveyThemeProvider } from '@sm/webassets/SurveyTheme/context';
import { formatTheme } from '@sm/webassets/SurveyTheme/helpers';
import { StaticContext } from '@sm/webassets/StaticContext';
import routeHandler from '~helpers/routeHandler';
import surveyTaking from '~helpers/pages/survey-taking';
import setSurveyTakingDocumentTitle from '~app/pages/SurveyTaking/helper/surveyTakingWindowTitle';
import getSpageGoogleFontsLink from '~app/helpers/spageGoogleFonts';
import isValidSubdomain from '~app/helpers/subdomain';
import Survey from '~app/pages/SurveyTaking/v2/Survey';
import { store } from '~app/storeV2';

import {
  initializeSurvey,
  setSurveyOwnerPackageId,
  setCollectorKey,
  initEnvironment,
} from '~app/pages/SurveyTaking/v2/slices/surveySlice';
import { ErrorType } from '~app/pages/SurveyTaking/errors';
import { setMeta } from '~app/pages/SurveyTaking/v2/slices/errorsSlice';
import { GetRespondentSurveyPageQuery, GetResponsesQuery, GetSpageSessionQuery } from '~lib/generatedGqlTypes';
import { CustomGetServerSideProps, LayoutData, Request, Response, ServerSideData } from '~helpers/middleware/types';
import { PageOptions, PageWithLayout } from '~helpers/pages/types';
import SEOBanner from '~app/components/SEOBanner';
import { FourOhFourErrorPage } from '~app/components/Errors';
import { StaticContextType } from '~app/pages/SurveyTaking/v2/types';
import { fetchLocaleMessages } from '~helpers/fetchLocaleMessages';
import { ANALYTICS_EVENTS } from '~app/components/amplitudeEnums';
import PageLayout from '~components/pageLayout';
import sendAmplitudeEvent from '~helpers/sendAmplitudeEvent';

const COPY = defineMessages({
  SEO_BANNER_CLOSE_BUTTON_ARIA_TEXT: {
    id: 'r.page.seoBannerCloseButtonAriaText',
    defaultMessage: 'close SEO banner',
  },
  SEO_BANNER_BODY: {
    id: 'r.page.seoBannerBody',
    defaultMessage: "Is this the survey you're looking for? Try creating your own with the world's leading platform.",
  },
  SEO_BANNER_SIGNUP_BUTTON: {
    id: 'r.page.seoBannerSignupButton',
    defaultMessage: 'Sign up free',
  },
  SEO_BANNER_LEARN_MORE: {
    id: 'r.page.seoBannerLearnMoreButton',
    defaultMessage: 'Learn more',
  },
});

/** Type for `Content` added via middleware to Request['pageProps'] */
type PageContent = {
  spageSessionData: GetSpageSessionQuery;
  surveyPageData: GetRespondentSurveyPageQuery;
  responsesData: GetResponsesQuery | null;
  collectorKey: string;
  pageOptions: PageOptions;
  isNotFound?: boolean;
};

type ServerSideProps = LayoutData &
  PageContent &
  Partial<ServerSideData> & {
    spageGoogleFontsLink: string | null;
  };

const log = logger.getLogger('respweb:page:surveyTaking');

const getRedirect = (destination: string, isPermanent: boolean): GetServerSidePropsResult<ServerSideProps> => {
  return {
    redirect: {
      destination,
      permanent: isPermanent,
    },
  };
};

export type SurveyTakingServerSideData = ServerSideData & {
  spageSessionData: GetSpageSessionQuery | null;
  surveyPageData: GetRespondentSurveyPageQuery | null;
  responsesData: GetResponsesQuery | null;
  collectorKey: string | null;
};

export const getServerSideProps: CustomGetServerSideProps<ServerSideProps> = async ({ req, res, query }) => {
  const handler = routeHandler({ query, pageDataLoader: surveyTaking, owners: '@spage' });
  await handler.run(req as Request, res as Response);

  if (!req.pageProps?.fetchServerSideDataSuccess) {
    // only redirect to ResponseWeb in production mode to avoid confusion during development
    if (process.env.NODE_ENV === 'development') {
      throw Error('Error Fetching ServerSideData via apps/respweb/helpers/pages/survey-taking/index.ts');
    }
    if (req?.pageProps?.isNotFound) {
      log.error('Collector Not Found', { collectorKey: req?.pageProps?.pageOptions?.collectorKey });
      // TODO: follow up in WEBPLAT-4505
      return getRedirect('/404', false);
    }
    if (req.url) {
      const parsedUrl = new URL(req.url, `http://${req.headers.host}`);
      parsedUrl.searchParams.set('rexr_p', 'current');
      parsedUrl.pathname = parsedUrl.pathname.replace('rx', 'r');
      return getRedirect(parsedUrl.toString(), false);
    }
    return getRedirect('/500', false);
  }

  const { collectorStatus, collectorId, encryptedSmParam, collectorAllowMultipleResponses } =
    req.pageProps?.pageOptions ?? {};
  const { slLanguageLocale, languageCode } = req.payloads?.staticData?.environment ?? {};

  if (collectorStatus === 'CLOSED') {
    return getRedirect(`/survey-closed/?sm=${encryptedSmParam || ''}&lang=${languageCode}`, false);
  }

  if (!collectorAllowMultipleResponses && req?.cookies[`RP_${collectorId}`]) {
    return getRedirect(`/survey-taken/?sm=${encryptedSmParam || ''}&lang=${languageCode}`, false);
  }

  const translationData = await fetchLocaleMessages(slLanguageLocale, languageCode);

  const spageGoogleFontsLink = getSpageGoogleFontsLink(
    req.pageProps?.spageSessionData.spageSession.survey.design?.theme
  );

  const pageProps = {
    props: {
      // added to the request via routeHandler
      ...(req.payloads as PageContent),
      // added to the requests via the pageDataLoaderFactory
      ...req.pageProps,
      translationData,
      spageGoogleFontsLink,
      layout: {
        variant: 'SurveyMonkey',
        options: {
          actionFlow: 'SurveyTaking',
          pageId: 'SurveyTaking',
          legacyWeb: 'responseweb',
          includeHeader: false,
          includeFooter: false,
        },
      },
    },
  };

  return pageProps;
};

const SurveyTaking: PageWithLayout<ServerSideProps> = ({
  pageOptions,
  spageSessionData,
  surveyPageData,
  collectorKey,
  spageGoogleFontsLink,
  responsesData,
}) => {
  const { reqLocale, slLanguageLocale, isWhiteLabel = false, canonical = '' } = pageOptions;
  const { collector, survey, encryptedSmParam, respondent, surveyOwnerPackageId } = spageSessionData.spageSession;
  const { environment, pageRequestId } = useContext<StaticContextType>(StaticContext);
  const collectorSubdomain = collector?.weblink?.subdomain;
  const subDomain = pageOptions?.subDomain ?? 'www';

  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      sendAmplitudeEvent(ANALYTICS_EVENTS.SURVEY_LOADED, {
        collector,
        survey,
        surveyOwnerPackageId,
        questions: surveyPageData.surveyPage?.surveyPageQuestions,
      });
      isMounted.current = true;
    }
  }, [collector, survey, surveyOwnerPackageId, surveyPageData.surveyPage?.surveyPageQuestions]);

  if (!survey || !isValidSubdomain([subDomain], collectorSubdomain)) {
    return <FourOhFourErrorPage requestId={pageRequestId} />;
  }

  // seo banner config
  const seoBannerCallToActionButtons = [
    {
      label: t(COPY.SEO_BANNER_SIGNUP_BUTTON),
      uri: 'https://www.surveymonkey.com/user/sign-up/?ut_source=s_page_search_topbar_desktop',
    },
    {
      label: t(COPY.SEO_BANNER_LEARN_MORE),
      uri: 'https://www.surveymonkey.com/mp/take-a-tour/?ut_source=s_page_search_topbar_desktop',
    },
  ];

  const respondentCollectionMethod = respondent?.collectionMethod ?? null;
  const isTitleEnabled = survey?.design?.surveyTitle.enabled ?? true;
  const surveyTitle = survey?.titleHTML || survey?.title;

  if (!collector?.surveyId) {
    return null;
  }

  store.dispatch(initEnvironment(environment));
  store.dispatch(
    initializeSurvey({ respondentSession: spageSessionData?.spageSession, surveyTakingCurrentPage: surveyPageData })
  );

  store.dispatch(setSurveyOwnerPackageId(surveyOwnerPackageId));
  store.dispatch(setCollectorKey(collectorKey));

  store.dispatch(
    setMeta({
      [ErrorType.COLLECTOR_CLOSED]: { url: `/survey-closed/?sm=${encryptedSmParam}` },
      [ErrorType.REQUEST_IP_DISALLOWED]: { url: `/survey-closed/?sm=${encryptedSmParam}` },
    })
  );

  // TODO: fix this type later
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const surveyTheme = formatTheme({ survey: { ...survey } } as any);

  // SPAGE-7677: REX router debug info
  const domain = environment.domain.toLowerCase();
  const showRequestId = ['surveymonkey', 'research'].every(d => d !== domain);

  const weblinkConfirmationToggle = !!collector.weblink && (collector.confirmationEmailEnabled ?? false);

  /* @todo: Lang set on the document level https://jira.surveymonkey.com/browse/WEBPLAT-3418 */
  return (
    <ReduxProvider store={store}>
      <Head>
        <title>{setSurveyTakingDocumentTitle(isTitleEnabled, isWhiteLabel, surveyTitle)}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=yes" />
        <meta name="robots" content="noindex, nofollow" />
        <meta httpEquiv="content-language" content={slLanguageLocale} />
        <link rel="canonical" href={canonical} />
        {!!spageGoogleFontsLink && <link rel="stylesheet preload" href={spageGoogleFontsLink} as="style" />}
        {/* TODO: Part of WEBPLAT-3482
         * The earlier HTML <link> tag incorrectly set `async` attribute which exists only in `<script>` tags.
         * The above line can achieve similar effect whereas might be better go with the below line as well.
         *
         * <link rel="stylesheet" href={spageGoogleFontsLink} media="print" onload={this.media='all'} /> */}
      </Head>
      <SurveyThemeProvider theme={surveyTheme}>
        <SEOBanner
          callToActionButtons={seoBannerCallToActionButtons}
          closeButtonAriaText={t(COPY.SEO_BANNER_CLOSE_BUTTON_ARIA_TEXT)}
          requestFromSearchEngine={false}
          collectionMethod={respondentCollectionMethod}
          collectorType={collector?.type}
        >
          {t(COPY.SEO_BANNER_BODY)}
        </SEOBanner>
        <Survey
          pageRequestId={pageRequestId}
          showRequestId={showRequestId}
          reqLocale={reqLocale ?? undefined}
          showWeblinkConfirmationToggle={weblinkConfirmationToggle}
        />
      </SurveyThemeProvider>
    </ReduxProvider>
  );
};

SurveyTaking.getLayout = function getLayout(page) {
  const { layout, staticData, translationData } = page.props;

  return (
    <PageLayout layout={layout} staticData={staticData} translationData={translationData}>
      {page}
    </PageLayout>
  );
};

export default SurveyTaking;
