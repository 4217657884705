/** The maximum number of questions which can display on a page */
export const MAX_QUESTIONS_ON_PAGE = 105;

/** The default return values for survey submission */
export const DEFAULT_SUBMIT_RESULT = {
  encryptedSmParam: '',
  encryptedSMParamforQuizResults: '',
  success: false,
  userErrors: null,
  respondent: null,
};
